<template>
  <div class="min-height position-relative flex-column justify-content-start align-items-center">
    <img src="@/assets/image/pc/6.jpg" width="100%" style="margin-bottom: 3vh" alt="" />

    <van-form class="width-100" @submit="onSubmit">
      <div class="block3 br-mid flex-column justify-content-start align-items-center" style="margin-left: 25%">
        <van-cell-group class="width-100">
          <div class="width-100 margin-bs flex-row justify-content-start align-items-center">
            <p class="fw-mid fs-big margin-r">举报人信息</p>
            <img src="@/assets/image/pc/4.jpg" height="20rem" alt="" />
          </div>
          <van-field
            v-show="reportShow"
            v-model="form.reportName"
            name="真实姓名"
            label="举报人"
            placeholder="请输入举报人姓名"
            :rules="[{ validator: validatorAnonymous }]"
          />
          <van-field
            v-show="reportShow"
            v-model="form.reportMobile"
            name="手机号码"
            label="举报人电话"
            placeholder="请输入举报人电话"
            :rules="[{ validator: validatorMobileAnonymous, message: '请输入正确的手机号码' }]"
          />
          <van-field name="是否匿名" label="是否匿名">
            <template #input>
              <van-radio-group v-model="form.isAnonymous" direction="horizontal" @input="onAnonymousChange">
                <van-radio name="true">是</van-radio>
                <van-radio name="false">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="width-100 margin-bs flex-row justify-content-start align-items-center">
            <p class="fw-mid fs-big margin-r">举报内容</p>
            <img src="@/assets/image/pc/4.jpg" height="20rem" alt="" />
          </div>
          <van-field v-model="form.childName" name="姓名" label="未成年人姓名" placeholder="请输入未成年人姓名" />
          <van-field name="性别" label="未成年人性别">
            <template #input>
              <van-radio-group v-model="form.childGender" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field v-model="form.childAge" name="未成年人年龄" label="未成年人年龄" placeholder="请输入未成年人年龄" />

          <van-field v-model="form.perpetratorName" name="侵害人姓名" label="侵害人姓名" placeholder="请输入侵害人姓名" />

          <van-field name="性别" label="侵害人性别">
            <template #input>
              <van-radio-group v-model="form.perpetratorGender" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <div class="width-100 flex-row justify-content-end">
            <van-field
              v-model="form.reportLocation"
              name="详细地址"
              label="事件发生地点"
              placeholder="请输入事件发生地点"
              :rules="[{ required: true }]"
            />
          </div>

          <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
            <p class="fs-mid darkgrey" style="width: 6.2em; margin-right: 12px">发现时间</p>
            <el-date-picker v-model="form.happenedTime" type="date" placeholder="选择发现时间" value-format="yyyy-MM-dd"> </el-date-picker>
            <van-field type="hidden" v-model="form.happenedTime" :rules="[{ required: true, message: '请选择发现时间' }]"> </van-field>
          </div>

          <p class="fs-mid darkgrey">事件概况</p>
          <van-field
            v-model="form.reportContent"
            rows="4"
            autosize
            label=""
            type="textarea"
            placeholder="请详细描述事件经过"
            :rules="[{ required: true }]"
          />
          <p class="fs-mid darkgrey">附件 <span class="grey fs-sml">(图片，最多上传9张)</span></p>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="imgList" max-count="9" :after-read="afterRead" />
            </template>
          </van-field>
        </van-cell-group>
        <div class="width-50 flex-row justify-content-around" style="margin-top: 5vh">
          <van-button class="width-40 margin-r box-shadow" color="rgb(47,122,247)" size="normal" native-type="submit">提交</van-button>
          <van-button class="width-40 margin-l box-shadow" plain color="rgb(47,122,247)" size="normal" native-type="reset">重置</van-button>
        </div>
      </div>
    </van-form>
    <div class="width-100 fs-sml bg-slightGrey darkgrey footer position-absolute textAlign-c">
      版权所有 © 浙江省瑞安市人民检察院 备案号：京ICP备10217144号-1
    </div>
  </div>
</template>
<script>
import { clueReportSave, uploadFile } from '@/api/reportApi';
import { Toast } from 'vant';
export default {
  name: 'pcclue',
  data() {
    return {
      form: {
        zlbId: '',
        reportName: '',
        reportMobile: '',
        isAnonymous: 'false',
        childName: '',
        childGender: '',
        childAge: '',
        perpetratorName: '',
        perpetratorGender: '',
        reportLocation: '',
        happenedTime: '',
        reportContent: '',
        reportImg: '',
        origin: 'gw'
      },
      reportShow: true,
      imgList: []
    };
  },
  created() {
    let zlbId = this.$Cookies.get('zlbId');

    if (zlbId == null) {
      this.$router.push('/pcIndexClue');
    }
    this.form.zlbId = zlbId;
    this.form.reportName = this.$Cookies.get('userName');
    this.form.reportMobile = this.$Cookies.get('userMobile');
  },

  methods: {
    handleChange(value) {
      console.log(value);
    },
    onAnonymousChange(checked) {
      if (checked == 'true') {
        this.reportShow = false;
      } else {
        this.reportShow = true;
      }
    },
    validatorAnonymous(val) {
      if (this.form.isAnonymous == 'true') {
        return true;
      } else if (!val) {
        return false;
      }
      return true;
    },
    validatorMobileAnonymous(val) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (this.form.isAnonymous == 'true') {
        return true;
      } else if (val == '' || val <= 10 || !reg.test(val)) {
        return false;
      }
      return true;
    },
    validatorMobile(val) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (val == '' || val <= 10 || !reg.test(val)) {
        return false;
      }
      return true;
    },
    onSubmit() {
      let imgs = '';
      for (let i = 0; i < this.imgList.length; i++) {
        if (imgs == '') {
          imgs += this.imgList[i].fileName;
        } else {
          imgs += ',' + this.imgList[i].fileName;
        }
      }
      if (this.form.isAnonymous == 'true') {
        this.form.reportName = '';
        this.form.reportMobile = '';
      }
      this.form.reportImg = imgs;
      let toast = Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      clueReportSave(this.form).then((response) => {
        toast.clear();
        if (response.code == 200) {
          Toast.success('提交成功');
          this.reset();
          setTimeout(function () {
            this.$router.push('/pcListClue');
          }, 1800);
        } else {
          Toast.fail('提交失败，请稍后再试');
        }
      });
    },
    reset() {
      this.form = {
        zlbId: '',
        reportName: '',
        reportMobile: '',
        isAnonymous: 'false',
        childName: '',
        childGender: '',
        childAge: '',
        perpetratorName: '',
        perpetratorGender: '',
        reportLocation: '',
        happenedTime: '',
        reportContent: '',
        reportImg: '',
        origin: 'gw'
      };
      this.imgList = [];
    },
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      let formFile = new FormData();
      formFile.append('file', file.file);

      uploadFile(formFile).then((response) => {
        if (response.code == 200) {
          file.status = 'done';
          file.message = '';
          file.url = response.url;
          file.fileName = response.fileName;
        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.block3 {
  width: 50%;
  padding-bottom: 10rem;
  margin: 0;
  min-width: 400px;
  .title-box {
    margin-bottom: 3rem;
  }
  .title {
    font-size: 1.2rem;
    //color: #4f9eea;
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
/deep/.van-cell {
  //background: #fafafa;
  //padding: 6px 16px;
  //border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
}
/deep/.van-cell::after {
  border: none;
}
/deep/.van-field__control {
  border: 1px solid #eeeeee;
  padding: 5px 16px;
}
/deep/.van-field__control--custom {
  border: none !important;
}

.footer {
  padding: 3rem;
  bottom: 0;
}
</style>
